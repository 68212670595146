<template>
  <section class="en-m-index-grid m-padding">
      <div class="m-grid" v-if="data.length > 4">
        <router-link :to="{path: item.link_en, query: {id: item.id}}" class="m-grid-item" v-for="(item,id) in data" :key="id">
          <img :src="item.logo_pic_url_en" alt="mooby">
          <span>{{item.title_en}}</span>
        </router-link>
      </div>
  </section>
</template>

<script>
export default {
  name:'IndexGrid',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang="less" scoped>
.en-m-index-grid {
  background: #EBF1FF;
  height:79px;
  width: 100%;
  padding-top: 1px;
  .m-grid {
    margin: 8px 0 8px 0;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: scroll;
    // grid-template-columns: repeat(5, 52px);
    // grid-template-rows: 69px;
    // grid-column-gap: 20px;
    .m-grid-item {
      width: 52px;
      display: flex;
      margin-left: 20.75px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:nth-child(1) {
        margin: 0;
      }
      img {
        width: 45px;
        height: 45px;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        margin-top: 2px;
        color: #444;
        letter-spacing: 0px;
        display: inline-block;
        width: 78px;
        transform: scale(0.9);
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>