<template>
  <div class="left-bar-all">
    <div class="more-nav" @click="showLeftBar = true">
      <van-icon name="wap-nav" :badge="badge"/>
    </div>
    <van-popup  v-model="showLeftBar" position="left" :style="{ width: '70vw', height: 'calc(100% + 1px)', maxHeight: '101%' }">
      <div @click="handleAvatar()" class="left-bar-top">
        <img :src="user.pic_url == '' ? $store.state.avatar :  user.pic_url" alt="avatar">
        <div class="user-info">
          <h3>
            {{user.nickname}}
            <img src="@/assets/leftbar/icon_xingbie@2x.png" alt="">
          </h3>
          <p>{{user.phone == '' ? user.email : user.phone}}</p>
        </div>
      </div>
      <div class="bar-link-item" v-for="(item, index) in leftBar" :key="index" @click="gotoLink(item, index)" :class="{'sliderActive' : item.active}">
        <img :src="item.icon" alt="">
        <div class="badge" v-if="item.name == 'Your Account' && badge != '' ? true : false">{{badge}}</div>
        <span>{{item.name}}</span>
        <img src="@/assets/leftbar/icon_more@2x.png" alt="" v-if="item.children">
        <ul v-if="item.children">
          <li v-for="(it, ind) in item.children" :key="ind" @click="gotoLink(it, ind)">{{it.name}}</li>
        </ul>
        <div class="dot" v-if="index == 5 && isRed"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getAccountInfo } from '@/api/zh/mine.js'
import { news_total } from "@/api/zh/message.js";
import { checkPartner } from '@/api/zh/partner.js'
export default {
  props:['badge'],
  data() {
    return {
      showLeftBar: false, // 侧边导航
      leftBar: [
        {name: 'Home',                 icon: require('@/assets/leftbar/icon_home@2x.png'),        path: '/en'},
        {name: 'Categories',           icon: require('@/assets/leftbar/icon_Categories@2x.png'),  path: '/en/classify'},
        {name: 'Your Orders',          icon: require('@/assets/leftbar/icon_YOrders@2x.png'),     path: '/en/order/0'},
        {name: 'Wishlist',             icon: require('@/assets/leftbar/icon_Wishlist@2x.png'),    path: '/en/favorite'},
        {name: 'Followed Stores',      icon: require('@/assets/leftbar/follow.png'),    path: '/en/followed/list'},
        {name: 'My Account',           icon: require('@/assets/leftbar/icon_ Account@2x.png'),    path: '/en/account'},
        {name: 'Comments',             icon: require('@/assets/leftbar/icon_pingjia@2x.png'),     path: 'en/comment/list'},
        {name: 'Notifications',        icon: require('@/assets/leftbar/icon_ Account@2x(1).png'), path: '/en/msg'},
        {name: 'Customer Service',     icon: require('@/assets/leftbar/icon_ Account@2x(3).png'), path: '/en/customer'},
        {name: 'Business Cooperation', icon: require('@/assets/leftbar/icon_ Account_2@2x.png'),  path: '', active: false, children: [
          {name: 'Partner', path: '/en/partner/type', click: 'Partner'},
          {name: 'Open Store', path: '/en/merchant/type'}
        ]},
        {name: 'Settings',             icon: require('@/assets/leftbar/icon_ Account@2x(2).png'), path: '', active: false, children: [
          {name: 'Language', path: '/en/language'},
          {name: 'Legal & About', path: '/en/setting/terms'},
          // {name: 'Switch Accounts', path: '/en/change'},
          {name: 'Sign Out', path: '', click: 'signout'}
        ]}
      ],
      user: { pic_url: '', nickname: 'Login', phone: '', email: '' },
      isRed: false,
    }
  },
  methods: {
    getData() {
      news_total().then((res) => {
        if (res) {
            if (res.logistics_message == 0 && res.site_message == 0) {
                this.isRed = false
            }else{
               this.isRed = true
            }
        }
      });
    },
    gotoLink(item, index) {
      if (item.click && item.click == 'Partner') {
         // 判断是否已经成为合伙人
        checkPartner().then(res => {
            if(res) {
              // status  审核状态：0未申请 1审核中；2审核通过；3审核未通过
              if (res.status == 0) {
                item.path == '/en/partner/type'
              } else if(res.status == 2){
                item.path = '/en/wallet-agent'
              }else {
                item.path = '/en/partner/application/1'
              }
            }
        })
        
        // return false
      }
      if(item.click && item.click == 'signout') {
        // 退出
        this.$dialog.confirm({
          title: 'Sign Out',
          message: 'Click the confirm button to continue to exit!',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancle'
        }).then(() => {
          localStorage.setItem('token', null)
          this.user = { pic_url: '', nickname: 'MoobyYoho', phone: '', email: '' }
          this.showLeftBar = false
        }).catch(() => {
          // on cancel
        })
      }else{
        if(item.path == this.$route.path) {
          this.showLeftBar = false
        } else if(item.path == '' || item.path == this.$route.path) {
          this.$set(this.leftBar[index], 'active', !item.active)
        }else{
          this.$router.push(item.path)
        }
      }
    },

    //获取用户信息
    getAccount() {
      // 个人中心 信息
      getAccountInfo().then(res => {
        if(res) {
          this.user = res.data
          localStorage.setItem('setPersonal',JSON.stringify(res.data))
        }
      })
    },

    // 点击头像  如果未登录 跳转到登陆页面  已登录 跳转到个人信息页面
    handleAvatar() {
      if(localStorage.getItem('token')) {
          this.$router.push('/en/setting/user')
      }else{
        this.$router.push('/en/login')
      }
      
    }

  },
  created() {
    this.leftBar.forEach((el,index)=>{
      if (this.$store.state.isMiniProgram == true && el.name == 'Business Cooperation') {
        this.leftBar.splice(index,1)
      }
    })
  },
  activated() {
    this.showLeftBar = false
    if(localStorage.getItem('token')) {
        this.getData();
    }
    this.user  = JSON.parse(localStorage.getItem('setPersonal')) || { pic_url: '', nickname: 'Login', phone: '', email: '' };
  }
}
</script>

<style scoped lang='less'>
.left-bar-all{
    .more-nav{width:60px;height:46px;line-height:52px;
      .van-icon{font-size: 24px;color:#0854BF}
    }
    .left-bar-top{width:100%;height:20vw;display: flex;padding-left:3vw;margin-top:2vw;margin-bottom:2vw;
      >img{display: block;width:15vw;height:15vw;object-fit: cover;border-radius: 50%;margin:2.5vw;border:1px solid #e1e1e1}
      .user-info{flex: 1;height:100%;
        h3{font-size: 16px;color:#444;font-weight: 600;line-height: 8vw;margin-top:3.4vw;
          img{width:5vw;height:5vw;display: inline-block;vertical-align: middle;}
        }
        p{
          font-size: 3.2vw;
          font-weight: 300;
          color: #666666;
        }
      }
    }
    .bar-link-item{padding:3vw 5vw;position: relative;
      .badge{position: absolute;top: 3px;left: 30px;width: 16px;height: 16px;border-radius: 50%;font-size: 12px;background: #f00;color: #fff;line-height: 16px;text-align: center;}
      img{width:6vw;height:6vw;
        &:nth-of-type(2){width:22px;height:22px;float: right;margin-right:-5px;transform: rotate(180deg);transition: transform 0.5s;}
      }
      span{
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
        vertical-align: 1.5vw;
        margin-left:2vw
      }
      ul{height:0;transition: all 0.5s;opacity: 0;overflow: hidden;
        li{width:100%;padding:3vw 0 3vw 8vw;
          font-size: 14px;
          font-weight: normal;
          color: #333333;
        }
      }
    }
    .sliderActive{
      ul{height:auto !important;opacity: 1;}
      img:nth-of-type(2){transform: rotate(0deg);}
    }
    .dot{position: absolute;top:10px;left:20px;width:6px;height: 6px;border-radius: 50%;background-color: #f04d2c;}
}
</style>