<template>
  <section class="m-index-search" :style="`background-color:#EBF1FF`">
    <div class="m-search-wrap  m-padding">
      <leftBar :badge="badge" />
      <div class="m-logo">
        <img src="@/assets/index/LOGO.png" alt="logo">
      </div>
      <img class="m-shop" src="@/assets/index/icon_shop_b@3x.png" alt="zh" @click="gotoShop">
      <m-dot :number="$store.state.cart" :animate="false" ref="dot" class="dot"></m-dot>
      <img class="m-lang" src="@/assets/index/icon_china-en_a@2x.png" alt="zh" @click="changeLang">
    </div>
    <router-link to="/en/search" class="m-search-input">
      <img class="m-search-icon" src="@/assets/public/icon_search@2x.png" alt="search">
      <span>What are you looking for?</span>
    </router-link>
  </section>
</template>

<script>
import MDot         from '@/components/en/m-dot.vue'
import leftBar from '@/components/en/left-bar.vue'
export default {
  name:'IndexSearch',
  props:['background', 'offsetTop', 'badge'],
  components: { leftBar, MDot },
  data() {
    return {
    }
  },
  methods: {
    changeLang() {
      // 切换语言
      let lang = localStorage.getItem('lang')
      if(!lang) {
        lang = 'zh'
      }

      if(lang == 'zh') {
        localStorage.setItem('lang', 'en')
        this.$router.push('/en')
      }else{
        localStorage.setItem('lang', 'zh')
        this.$router.push('/zh')
      }
    },
    gotoShop() {
      this.$router.push('/en/cart')
    }
  }
}
</script>

<style lang="less" scoped>
  .m-index-search {
    position: fixed;
    background: #EBF1FF;
    top: 0;
    left: 0;
    z-index: 99;
    height: 85px;
    .m-search-wrap {
      height: 44px;
      width: 100vw;
      display: flex;
      align-items: center;
      transition: all 0.5s;
      .m-shop,.m-lang{
        width: 24px;
        height: 22px;
        object-fit: cover;
      }
      .m-shop{margin-right:14px}
      .m-logo {
        flex: 1;
        img{
          width: 128px;
          height: 20px;
          margin:0 auto;
          display: block
        }
      }
    }
    .m-search-input {
      flex: 1;
      margin:0 16px;
      padding:0 15px;
      background-color: #fff;
      border-radius: 20px;
      height: 32px;
      display: flex;
      align-items: center;
      box-shadow: 0 0 10px #cad6f3;
      span {
        font-size: 12px;
        color: #999;
        margin-left:10px
      }
      .m-search-icon {
        width: 18px;
        height: 18px;
      }
    }
    .dot{position: absolute;right:65px;top:14px}
  }
</style>