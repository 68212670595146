/** 
 *   首页
 */
 import { get, post } from '@/untils/js/axios.js'

 // 获取独立板块列表
 export const sectionList = params => get('/special/column-list', params)
 
 // 获取新品首发数据
 export const homeNew = params => get(`/goods/columns-goods/2`, params)
 
 // 获取热门单品数据
 export const hotGoods = params => get('/goods/columns-goods/3', params)
 
 // 为你推荐商品列表显示(用户自定义)
 export const userRemGoodsList = params => get('/goods/columns-goods/1', params)
 
 // 为你推荐商品列表显示(全部)
 export const userAllRemGoodsList = params => get('/goods/recommend/index', params)
 
 // 获取地址列表
 export const getCityList = params => get('/city/getcity', params)
 
 // 获取首页轮播图
 export const getBanner = id => get(`/banner/${id}`)
 
 // 获取新品预售
 export const preSaleList = (id,params) => get(`/goods/pre-sale/${id}`, params)
 
 // 首页主题活动
 export const activityTheme = params => get('/activity/theme', params)
 
 // 首页主题活动列表
 export const activeList = (id,params) => get(`/activity/theme-main/${id}`, params)
 
 // 落地页活动
 export const landingPage = params => get('/activity/landing', params)
 
 // 落地页活动列表
 export const landingList = (id, params) => get(`/activity/landing-main/${id}`, params)
 
 // 夜市商品列表
 export const market_goods = params => get("/activity/night/market/goods",params);
 
 // 用户留存时间添加
 export const user_retain = params => post("/activity/night/user/retain",params);
 
 // 活动商品浏览量
 export const visit = params => post("/activity/night/market/goods/update/visit",params);